/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/
.required::after {
    content: " *";
    color: red;
}

.custom-form-group {
    margin-bottom: 0 !important;
}